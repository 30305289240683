// external imports
import { Redirect } from 'react-router-dom'

// constants
import {
  LOGIN,
  RECOVER_LOGIN,
  RECOVER_PASSWORD,
  REGISTER,
  CHANGE_DEFAULT_PASSWORD,
  RESET_PASSWORD,
  TERMS_ACCEPTANCE,
  TERMS_EXPANDED,
  NO_PROFILE_FOUND,
  NO_SCHOOL_PERIOD_FOUND,
  FORGOT_PASSWORD,
  SSO,
  NO_ENABLED_SCHOOL_FOUND,
  SSO_V2,
  INTELLECTUAL_PROPERTY_TERM
} from 'navigation/CONSTANTS'

// pages imports
import {
  Login,
  Register,
  Recover,
  ChangeDefaultPassword,
  ResetPassword,
  Terms,
  Expanded,
  NoProfileFound,
  NoSchoolPeriodFound,
  ForgotPassword,
  AuthSSO,
  NoEnabledSchoolFound,
  AuthSSO_V2
} from 'pages'

// local imports
import { IRoutesGroupProps } from '..'
import { IntellectualPropertyTerm } from 'pages/IntellectualPropertyTerm'

export const getPublicRoutes = (isUserLoggedIn: boolean): IRoutesGroupProps => ({
  routes: [
    {
      path: LOGIN,
      component: isUserLoggedIn ? () => <Redirect to={{ pathname: '/' }} /> : Login,
      isPrivate: false,
      exact: true,
      isOnlyNotAuth: true
    },
    {
      path: NO_ENABLED_SCHOOL_FOUND,
      component: NoEnabledSchoolFound,
      isPrivate: false,
      exact: true
    },
    {
      path: INTELLECTUAL_PROPERTY_TERM,
      component: IntellectualPropertyTerm,
      isPrivate: false,
      exact: true
    },
    {
      path: NO_PROFILE_FOUND,
      component: NoProfileFound,
      isPrivate: false,
      exact: true
    },
    {
      path: NO_SCHOOL_PERIOD_FOUND,
      component: NoSchoolPeriodFound,
      isPrivate: false,
      exact: true
    },
    {
      path: REGISTER,
      component: Register,
      isPrivate: false,
      exact: true,
      isOnlyNotAuth: true
    },
    {
      path: RECOVER_LOGIN,
      component: () => <Recover recoverType='login' />,
      isPrivate: false,
      exact: true,
      isOnlyNotAuth: true
    },
    {
      path: RECOVER_PASSWORD,
      component: () => <Recover recoverType='password' />,
      isPrivate: false,
      exact: true,
      isOnlyNotAuth: true
    },
    {
      path: RESET_PASSWORD,
      component: ResetPassword,
      isPrivate: false,
      exact: true,
      isOnlyNotAuth: true
    },
    {
      path: CHANGE_DEFAULT_PASSWORD,
      component: ChangeDefaultPassword,
      isPrivate: false,
      exact: true
    },
    {
      path: TERMS_ACCEPTANCE,
      component: Terms,
      isPrivate: false,
      exact: true
    },
    {
      path: TERMS_EXPANDED,
      component: Expanded,
      isPrivate: false,
      exact: true
    },
    {
      path: FORGOT_PASSWORD,
      component: ForgotPassword,
      isPrivate: false,
      exact: true,
      isOnlyNotAuth: true
    },
    {
      path: SSO,
      component: AuthSSO,
      isPrivate: false,
      exact: true
    },
    {
      path: SSO_V2,
      component: AuthSSO_V2,
      isPrivate: false,
      exact: true
    }
  ]
})
