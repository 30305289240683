import React, { useState, useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Box, Paper, IconButton, Typography, Stack, useMediaQuery, useTheme } from '@mui/material'
import {
  ChevronLeft,
  ChevronRight,
  ZoomIn,
  ZoomOut,
  Download
} from '@mui/icons-material'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface PDFViewerProps {
  pdfUrl: string
  initialPage?: number
}

export const PDFViewer: React.FC<PDFViewerProps> = ({ pdfUrl, initialPage = 1 }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState<number>(initialPage)
  const [scale, setScale] = useState<number>(1)
  const [containerWidth, setContainerWidth] = useState<number>(0)

  useEffect(() => {
    const updateWidth = () => {
      const width = window.innerWidth
      setContainerWidth(width)
      if (isMobile) {
        setScale(width / 900)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [isMobile])

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages)
  }

  const changePage = (offset: number): void => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const handleZoomIn = () => {
    setScale(prev => Math.min(prev + 0.1, 2))
  }

  const handleZoomOut = () => {
    setScale(prev => Math.max(prev - 0.1, 0.5))
  }

  const handleDownload = async () => {
    try {
      const response = await fetch(pdfUrl)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = pdfUrl.split('/').pop() ?? 'document.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Download failed:', error)
    }
  }

  const TopControls = () => (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      justifyContent='center'
    >
      <IconButton onClick={handleZoomOut} size='small'>
        <ZoomOut />
      </IconButton>

      <Typography variant='body2' sx={{ minWidth: 40 }}>
        {(scale * 100).toFixed(0)}%
      </Typography>

      <IconButton onClick={handleZoomIn} size='small'>
        <ZoomIn />
      </IconButton>

      <IconButton onClick={handleDownload} size='small'>
        <Download />
      </IconButton>
    </Stack>
  )

  const BottomControls = () => (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      justifyContent='center'
    >
      <IconButton
        onClick={() => changePage(-1)}
        disabled={pageNumber <= 1}
        size='small'
      >
        <ChevronLeft />
      </IconButton>

      <Typography variant='body2'>
        {pageNumber}/{numPages}
      </Typography>

      <IconButton
        onClick={() => changePage(1)}
        disabled={pageNumber >= (numPages ?? 0)}
        size='small'
      >
        <ChevronRight />
      </IconButton>
    </Stack>
  )

  const DesktopControls = () => (
    <Stack
      direction='row'
      spacing={2}
      alignItems='center'
      justifyContent='center'
    >
      <BottomControls />
      <TopControls />
    </Stack>
  )

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'transparent',
        mt: isMobile ? '48px' : 0
      }}
    >
      {isMobile && (
        <Paper
          elevation={4}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            backgroundColor: 'white',
            py: 0.5,
            px: 1
          }}
        >
          <TopControls />
        </Paper>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          maxWidth: '100%',
          overflowX: 'auto',
          overflowY: 'auto',
          mb: isMobile ? '48px' : '64px',
          backgroundColor: 'transparent',
          '& .react-pdf__Page': {
            maxWidth: '100%',
            '& canvas': {
              maxWidth: '100%',
              height: 'auto !important'
            }
          }
        }}
      >
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={scale}
            width={containerWidth - (isMobile ? 32 : 64)}
          />
        </Document>
      </Box>

      <Paper
        elevation={4}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: 'white',
          py: 0.5,
          px: 1
        }}
      >
        {isMobile ? <BottomControls /> : <DesktopControls />}
      </Paper>
    </Box>
  )
}
